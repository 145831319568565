.color-picker {
  background: rgb(255, 255, 255);
  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  border-radius: 4px;
  position: absolute;
  :root[dir="ltr"] & {
    left: -5.5px;
  }
  :root[dir="rtl"] & {
    right: -5.5px;
  }
}

.color-picker-control-container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.color-picker-triangle {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 9px 10px;
  border-color: transparent transparent rgb(255, 255, 255);
  position: absolute;
  top: -10px;
  :root[dir="ltr"] & {
    left: 12px;
  }
  :root[dir="rtl"] & {
    right: 12px;
  }
}

.color-picker-triangle-shadow {
  border-color: transparent transparent rgba(0, 0, 0, 0.1);
  top: -11px;
}

.color-picker-content {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 0.5rem;
}

.color-picker-content .color-input-container {
  grid-column: 1 / span 5;
}

.color-picker-swatch {
  position: relative;
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;
  border-radius: 4px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
}

.color-picker-swatch:focus {
  /* Note: in the original react-color it uses the color of the element,
     but it's too annoying to set from JavaScript without a css lib... */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.color-picker-transparent {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.color-picker-transparent,
.color-picker-label-swatch {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==")
    left center;
}

.color-picker-hash {
  background: #dee2e6;
  height: 1.875rem;
  width: 1.875rem;
  :root[dir="ltr"] & {
    border-radius: 4px 0px 0px 4px;
  }
  :root[dir="rtl"] & {
    border-radius: 0px 4px 4px 0px;
  }
  color: #495057;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
}
.color-input-container:focus-within .color-picker-hash {
  box-shadow: 0 0 0 2px #a5d8ff;
}
.color-input-container:focus-within .color-picker-hash::before,
.color-input-container:focus-within .color-picker-hash::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
}
.color-input-container:focus-within .color-picker-hash::before {
  background: #dee2e6;
  :root[dir="ltr"] & {
    right: -1px;
  }
  :root[dir="rtl"] & {
    left: -1px;
  }
}
.color-input-container:focus-within .color-picker-hash::after {
  background: #fff;
  :root[dir="ltr"] & {
    right: -2px;
  }
  :root[dir="rtl"] & {
    left: -2px;
  }
}

.color-input-container {
  display: flex;
}

.color-picker-input {
  width: 12ch; /* length of `transparent` + 1 */
  margin: 0;
  font-size: 1rem;
  color: #343a40;
  border: 0px;
  outline: none;
  height: 1.75em;
  box-shadow: #dee2e6 0px 0px 0px 1px inset;
  :root[dir="ltr"] & {
    border-radius: 0px 4px 4px 0px;
  }
  :root[dir="rtl"] & {
    border-radius: 4px 0px 0px 4px;
  }
  float: left;
  padding: 1px;
  padding-inline-start: 0.5em;
  appearance: none;
}

.color-picker-label-swatch {
  height: 1.875rem;
  width: 1.875rem;
  margin-inline-end: 0.25rem;
  border: 1px solid #dee2e6;
  position: relative;
  overflow: hidden;
  background-color: transparent !important;
}

.color-picker-label-swatch::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--swatch-color);
}

.color-picker-keybinding {
  position: absolute;
  bottom: 2px;
  :root[dir="ltr"] & {
    right: 2px;
  }
  :root[dir="rtl"] & {
    left: 2px;
  }
  font-size: 0.7em;
  color: #ccc;
}
