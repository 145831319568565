.ProjectName {
  display: inline-block;
  cursor: pointer;
  border: 1.5px solid #eee;
  line-height: 1;
  padding: 0.75rem;
  white-space: nowrap;
  border-radius: var(--space-factor);
}

.ProjectName:hover {
  background-color: #eee;
}

.ProjectName:focus {
  outline: none;
  box-shadow: 0 0 0 2px steelblue;
}
