.FixedSideContainer {
  --margin: 0.25rem;
  position: fixed;
  pointer-events: none;
}

.FixedSideContainer > * {
  pointer-events: all;
}

.FixedSideContainer_side_top {
  left: var(--margin);
  top: var(--margin);
  right: var(--margin);
  z-index: 2;
}

/* TODO: if these are used, make sure to implement RTL support
.FixedSideContainer_side_left {
  left: var(--margin);
  top: var(--margin);
  bottom: var(--margin);
  z-index: 1;
}

.FixedSideContainer_side_right {
  right: var(--margin);
  top: var(--margin);
  bottom: var(--margin);
  z-index: 3;
}
*/
